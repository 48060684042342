@import '../../Resources/SharedStyle/AppColors.scss';

.toast {
 position: absolute;
 top: 0;
 right: 30%;
 width: 600px;
 color: $liberty-blue
}

.toast-header.Error {
    background-color: $liberty-error-border;
}
.toast-header.Warning {
    background-color:  #ff9900;
}

.mr-auto {
    color: white;
}

.toast-header.Error svg,
.toast-header.Warning svg {
    color: white;
    margin: 2px 5px 0 0;
}

.close span {
    color: white;
}