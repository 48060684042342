.footer-container {
    background-color: #1a1446;
    color: #fff;
    min-height: 100px
}

.footer-logo {
    flex: 0 225px;
    height: 80px;
    margin-right: 1em
}

.footer-logo img {
    height: 100%;
    width: 100%
}

.footer-body {
    background-color: #1a1446;
    color: #fff;
    border-top: 5px solid #ffd000;
    display: flex;
    flex-flow: row;
    padding: .5em 2em;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-copyright {
    display: flex;
    flex: 1;
    flex-direction: column;
    font: .75rem arial;
    justify-content: center;
    line-height: 1.125em;
    margin-right: 1em;
    padding: 1em 0;
    text-align: left;
    width: 100%
}

.footer-copyright p {
    margin: 0
}

.footer-copyright p,

.footer-copyright p+p {
    color: #fff;
    font-family: Roboto, Arial, sans-serif;
    font-size: 12px
}

.footer-copyright p+p {
    margin-top: .5em
}

.footer-social-media-links {
    display: flex;
    flex: 0 auto;
    flex-direction: column;
    font-size: .75rem;
    justify-content: center;
    margin-left: auto;
    margin-right: 2em;
    padding: 1em 0
}

.footer-social-media-links p {
    color: #fff;
    font-family: Roboto-Medium, Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    white-space: nowrap
}

.footer-social-media-links .footer-social-icons {
    padding-top: .5em;
    text-align: center
}

.footer-social-media-links .footer-social-icons a {
    background-color: #fff;
    border-radius: 50px;
    display: inline-block;
    height: 30px;
    margin-right: 5px;
    text-align: center;
    vertical-align: top;
    width: 30px
}

.footer-social-media-links .footer-social-icons a svg {
    fill: #002663;
    flex: 1;
    height: 1.7em;
    margin-top: 5px;
    width: 1.7em
}

@media only screen and (max-width: 600px) { 
    .footer-logo, .footer-copyright, .footer-social-media-links {
        width: 100%;
    }
    
    .footer-body {
        position: relative;
    }
}