.default-btn {
  margin-top: 20px;
  border-radius: 25px;
  padding: 10px;
  width: 180px;
  color: #343741;
  font-weight: bold;
}

.btn-primary {
  background-color: #99E5EA;
  border-color: #99E5EA;
}

.btn-primary:hover {
  border-color: #343741;
  background-color: white;
  color: #343741;
}

.btn-primary:disabled {
  border-color: #343741;
  background-color: white;
  color: #343741;
  pointer-events: none;
}

.btn-secondary {
  border-color: #343741;
  background-color: white;
  color: #343741;
}

.btn-secondary:hover,
.btn-secondary:active {
  border-color: #99E5EA;
  background-color: #99E5EA;
  color: #343741;
}

.endDateBtn {
  border: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  color: #06748C !important;
  background-color: transparent;
}

.endDateBtn:hover {
  text-decoration: underline;
}