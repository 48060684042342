input[type=date]::-webkit-inner-spin-button,
input[type=time]::-webkit-inner-spin-button{
    -webkit-appearance: none;
    display: none;
}


.inputTime {
    font-size: 16px;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-color:  #C0BFC0;
    width: 39%;
    height: 55px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.floating-time-label {
    position: absolute;
    pointer-events: none;
    top: 2px;
    font-size: 11px;
    padding-left:15px;
    opacity: 1;
    z-index: 1;
  }

.selectBox {
    font-size: 16px;
    border-radius: 5px;
    border-style: solid;
    border-color: #C0BFC0;
    width: 100%;
    height: 55px;
    padding-left: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-width: 2px;
}

.inputTime:invalid {
    border-color: red;
}
.react-time-picker__wrapper{
    border: none;    
}

.react-time-picker__clear-button{
    width: 0;
    height:0;
}