@import '../../Resources/SharedStyle/AppColors.scss';

.PageContent {
    margin: 20px 0;
}

.containerStyle {
    margin-top:30px;
    margin-bottom: 10px;
    background: #fff;
    padding: 30px 40px 40px;
    border-radius: 5px;
    max-width: 800px;
}