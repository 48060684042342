$liberty-blue: #1A1446;
$liberty-dark-teal: #06748C;
$liberty-medium-teal: #28A3AF;
$liberty-teal: #99E5EA;
$liberty-light-teal: #F2FCFC;
$liberty-yellow: #FFD000;
$liberty-white: #fff;
$liberty-grey: #eeeeee;
$liberty-dark-grey: #343741;
$liberty-links: #06748C;
$box-shadow: 0 .5px 3px 0 rgba(0, 0, 0, 0.25);
$box-shadow-hover: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
$box-shadow-hover-heavy: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

$liberty-success-box-fill: #E1F2E6;
$liberty-success-border: #03AC63;
$liberty-success-text: #008040;

$liberty-error-box-fill: #FFF4F5;
$liberty-error-border: #E92243;
$liberty-error-text: #D32F2F;
