@import '../../../Resources/SharedStyle/AppColors.scss';

.questionToggle {
    margin-bottom: 65px;
}

.question {
    color: $liberty-blue;
    padding-top:7px;
    font-weight: 600;
    position: absolute;
}

.toggle {
    position: absolute;
    right:249px;
}
.active {
    border-color: $liberty-teal !important;
    background-color: $liberty-teal !important;
    color: $liberty-dark-grey !important;
}