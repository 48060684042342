@import './AppColors.scss';

.default-btn {
    margin-top: 20px;
    border-radius: 25px;
    padding: 10px;
    width: 180px;
    color: $liberty-dark-grey;
    font-weight: bold;
}

.btn-primary{
    background-color: $liberty-teal ;
    border-color: $liberty-teal;
}

.btn-primary:hover {
    border-color: $liberty-dark-grey;
    background-color: white;
    color: $liberty-dark-grey;
}

.btn-primary:disabled {
    border-color: $liberty-dark-grey;
    background-color: white;
    color: $liberty-dark-grey;
    pointer-events: none;
}

.btn-secondary {
    border-color: $liberty-dark-grey;
    background-color: white ;
    color: $liberty-dark-grey ;
}

.btn-secondary:hover,
.btn-secondary:active{
    border-color: $liberty-teal ;
    background-color: $liberty-teal ;
    color: $liberty-dark-grey ;
}