.HeaderContainer {
    background: #ffd000;
    text-align: left;
    padding: .5rem 1rem;
}

.HeaderContainer img {
    width: 10rem;
    margin: 9px 0 0 0;
}

.NavBarBackground {
    background-color: #1a1446;
    padding-left: .75em;
    padding-right: .75em;
    float: left;
    width: 100%;
}

.FlexIt {
    background-color: #1a1446;
    margin: 0;
    width: 100%;
    float: left;
}

.TechHelpHeading {
    cursor: pointer;
    float: left;
}

.TechHelpIcon {
    width: 2em;
    margin-left: .5em;
    padding: 0;
}

.Thick {
    font-weight: 800;
}

.White {
    color: #fff;
}

.Skinny {
    font-weight: 300;
}

.SecondaryNav {
    color: white;
    margin: 12px 1em 0 0;
    float: right;
}

.SecondaryNav span {
    margin: 0 0 0 5px;
}