.inputText:focus ~ .floating-label,
.inputText:not(:focus):valid ~ .floating-label,
.invalidInputText:focus ~ .floating-label,
.invalidInputText:not(:focus):valid ~ .floating-label,
.optionalEmptyInputText:focus ~ .floating-label{
  top: 35px;
  bottom: 10px;
  left: 20px;
  font-size: 11px;
  opacity: 1;
}

.inputText,
.invalidInputText,
.optionalEmptyInputText {
  font-size: 16px;
  border-radius: 5px;
  border-style: solid;
  border-color:  #C0BFC0;
  width: 100%;
  height: 55px;
  padding-left: 10px;
  padding-top: 10px;
}

.inputText:focus,
.invalidInputText:focus
.optionalEmptyInputText:focus {
    top: 20px;
}

.invalidInputText {
  border-color:  #d60909;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  padding-left: 10px;
  left: 30px;
  top: 48px;
  transition: 0.2s ease all;
} 

.formSearchIcon {
  position: absolute;
  right: 16px;
  top: 33px;
  height: 52px;
}

.inputError {
  color: #d60909;
  font-size: 14px;
}

.errorIcon {
  padding-left: 10px;
}