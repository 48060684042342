input[type=date]::-webkit-inner-spin-button{
    -webkit-appearance: none;
    display: none;
}
.inputDate {
    position: relative;
    font-size: 16px;
    border-radius: 5px;
    border-style: solid;
    border-color:  #C0BFC0;
    width: 100%;
    height: 55px;
    padding-left: 40px;
    margin-bottom: 10px;  
}
.stationary-floating-date-label{
    position: absolute;
    pointer-events: none;
    top: 2px;
    font-size: 11px;
    padding-left:45px;
    opacity: 1;
    z-index: 1;
}
.formDateIcon {
    position: absolute;
    pointer-events: none;
    top: 18%;
    padding-left: 5px;
    z-index: 1;
}

.react-time-picker_wrapper{
    display:flex;
    flex-grow: 1;
    flex-shrink: 0;
}
