@import '../../../Resources/SharedStyle/AppColors.scss';

.reportList {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    top: -16px;
    left: 16px;
    background: $liberty-white;
    list-style: none;
    z-index: 9;
    border-radius: 0 0 5px 5px;
    min-width: 80%;
    overflow: scroll;
    max-height: 300px;
    overflow:auto;
}

.oooStatusOn {
   background-color: $liberty-success-box-fill;
   color:  $liberty-success-text;
   border: 1px solid $liberty-success-border;
   border-radius: 5px;
}

.oooStatusOff {
    background-color: $liberty-error-box-fill;
    color:  $liberty-error-text;
    border: 1px solid $liberty-error-border;
    border-radius: 5px;
 }

.reportList table, .loadingBlock {
    border: 1px solid #C0BFC0;
}

.reportList td {
    border-bottom: 1px solid #C0BFC0;
    padding: 10px;
    list-style: none;
}

.reportList tr:last-child{
    border-bottom: 0;
}

.reportList span {
    display: block;
    font-weight: normal;
}

.reportList small {
    font-size: 100%;
}
.noMatch {
    padding: 10px;
    text-align: center;
}