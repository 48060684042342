.ModalHeader {
  background-color: #1A1446;
  color: white;
}

.ModalButton {
  background-color: #1A1446 !important;
  color: black;
}

.ModalBody {
  padding: 1em;
}

.ModalFooter {
  background-color: whitesmoke;
}

.ModalFooter-centered {
  background-color: whitesmoke;
  justify-content: center !important;
}

.dialog-btn {
  border-radius: 25px;
  font-weight: bold !important;
  min-width: 10em;
  text-align: center;
  font-size: 1rem;
}

.dialog-btn-primary {
  color: #1A1446;
  border: 1px solid #99E5EA;
  background-color: #99E5EA;
}

.dialog-btn-primary:hover {
  color: #1A1446;
  background-color: #fff;
  border: 1px solid #1A1446;
}

.dialog-btn-secondary {
  color: #fff;
  border: 1px solid #1A1446;
  background-color: #1A1446;
}

.dialog-btn-secondary:hover {
  color: #1A1446;
  background-color: #fff;
}

.dialog-btn-white {
  border: 1px solid #1A1446;
  color: #1A1446;
  background-color: white;
  text-align: center;
}

.dialog-btn-white:hover {
  background-color: #99E5EA;
  border: 1px solid #99E5EA;
  color: #1A1446;
}