@import './AppColors.scss';

.ModalHeader {
    background-color: $liberty-blue;
    color: white;
}

.ModalButton {
    background-color: $liberty-blue !important;
    color: black;
}

.ModalBody {
    padding: 1em;
}

.ModalFooter {
    background-color: whitesmoke
}

.ModalFooter-centered {
    background-color: whitesmoke;
    justify-content: center !important;
}