.default-btn {
  margin-top: 20px;
  border-radius: 25px;
  padding: 10px;
  width: 180px;
  color: #343741;
  font-weight: bold;
}

.btn-primary {
  background-color: #99E5EA;
  border-color: #99E5EA;
}

.btn-primary:hover {
  border-color: #343741;
  background-color: white;
  color: #343741;
}

.btn-primary:disabled {
  border-color: #343741;
  background-color: white;
  color: #343741;
  pointer-events: none;
}

.btn-secondary {
  border-color: #343741;
  background-color: white;
  color: #343741;
}

.btn-secondary:hover,
.btn-secondary:active {
  border-color: #99E5EA;
  background-color: #99E5EA;
  color: #343741;
}

.OOOForm {
  background-color: white;
  padding: 0px 40px 40px;
  border-radius: 5px;
}

.formHeading {
  color: #1A1446;
  font-size: 1.5em;
  font-weight: 600;
}

.formSubHeadings {
  font-size: 1.2em;
  padding: 1em 0;
}

.floatRight {
  float: right;
}

.directBlock {
  position: relative;
}